<template>
    <div class="sup_content website_list">
        <div class="sup_page_heading">
            <h2>Free Website List</h2>
        </div>
        <div class="sup_inner_content">            
            <DataTable :v-for = "allFreeWebsiteData.data" class="p-datatable order-data-table" :paginator="true" :value="allFreeWebsiteData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allFreeWebsiteData.loading" responsiveLayout="scroll" removableSort :filters="filters" v-model:selection="allFreeWebsiteData.selectedItem" :dataKey="uuid" @row-select="selectedItem($event)" @row-select-all="selectedItem($event)">
                <template #loading>
                    Loading website data. Please wait.
                </template>
                <template #empty>
                    No Website found.
                </template>
                <Column v-if="allFreeWebsiteData.activityStatus !== 'active'" selectionMode="multiple" headerStyle="width: 3rem" :exportable="false"></Column>
                <Column field="siteName" header="Site Name" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        <div class="site_nameurl_list">
                            <span class="sup_site_info">{{slotProps.data.siteName}}</span> <a :href="'https://' + [slotProps.data.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.siteUrl}}</a>
                        </div>
                        
                    </template>
                </Column>

                <Column field="name" header="License Key" :sortable="true">
                    <!-- <template #body="slotProps">
                        <span v-if="slotProps.data.License && allFreeWebsiteData.activityStatus === 'active'" class="sup_copy_license" :data-license="slotProps.data.License.licenseKey"><input type="text" :value="slotProps.data.License.licenseKey" readonly /><i class="pi pi-copy" @click="copyLicense($event)" v-tooltip="'Copy License!'"></i></span>
                        <span v-else>{{slotProps.data.License.licenseKey}}</span>
                    </template> -->
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban'" icon="pi pi-copy" class="p-button-secondary p-button-rounded p-button-text p-button-sm" v-tooltip="'Copy Licence Key'" @click="copyKey($event)" />
                        <InputText class="up_copy_license d-none" v-if="slotProps.data.License !== null && slotProps.data.License.status === 'auth'" type="text" :value="slotProps.data.License.licenseKey" readonly />
                    </template>
                </Column>

                <Column field="name" header="Customer" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.User.name}}
                    </template>
                </Column>

                <Column field="serviceFor" header="Service Type" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.serviceFor.charAt(0).toUpperCase() + slotProps.data.serviceFor.slice(1)}}
                    </template>
                </Column>

                <Column field="freeStatus" header="Is Free">
                    <template #body="slotProps">
                        <label v-if="slotProps.data.License != null" :for="slotProps.data.uuid" :class="['sup_status_switch', slotProps.data.License.isFree ? 'sup_status_active' : 'sup_status_inactive', slotProps.data.deletedAt !== null ? 'sup_status_switch_disable' : '']" >
                            <input v-if="slotProps.data.License.isFree" type="checkbox" role="switch" :id="slotProps.data.uuid" checked>
                            <input v-else type="checkbox" role="switch" :id="slotProps.data.uuid">
                            <span class="sup_switch_label" data-active="Yes" data-inactive="No" @click="switchWebsiteStatus(slotProps.data.License.uuid, slotProps.data.License.isFree, $event)"></span>
                            <span class="sup_switch_handle"></span>
                        </label>
                    </template>
                </Column>

                <Column field="authStatus" header="License Status" sortable filterField="License" sortField="License.status">
                    <template #body="slotProps">
                        <!-- <span :class="['sub_license_status', slotProps.data.License === null ? 'warning' : slotProps.data.License.status === 'auth' ? 'success' : 'danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status.charAt(0).toUpperCase() + slotProps.data.License.status.slice(1)}}</span> -->

                        <span :class="['sup_item_status', slotProps.data.License === null ? 'sup_item_status_danger' : slotProps.data.License.status === 'auth' ? 'sup_item_status_success' : 'sup_item_status_danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status.charAt(0).toUpperCase() + slotProps.data.License.status.slice(1)}}</span>
                    </template>
                </Column>

                <Column field="createdAt" header="Created On" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                        <!-- {{usFormatDate(slotProps.data.createdAt.split("T")[0])}} {{slotProps.data.createdAt.split("T")[1].split(".")[0]}} -->
                    </template>
                </Column>
            </DataTable>
            <!--- Show Website list Table End --->
        </div>
        <!-- All actions notification -->
        <!-- <Toast position="top-right" /> -->
        <!-- All actions notification -->
    </div>
</template>

<script>
import { freeWebsites, allWebsite } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
// import userLogout from '../../../../helper/userLogout/userLogout';
import {usFormatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { reactive, onBeforeMount } from 'vue';
// import { useRoute } from "vue-router";

export default{
    name: 'freeWebsiteManagement',
    setup(){
        //Route call
        // const route = useRoute();

        //Vuex store data call fucntion
        const store = useStore();

        //Toast Message call
        const toast = useToast();

        //Website initial data storing state
        const allFreeWebsiteData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            print: false,
            search: null,
            sortBy: 'both',
            dateRange: null,
            userId: null,
            selectedItem: null
        });

        /**
         * On component get mounted require functions call
         */
        onBeforeMount(async() => {
            getAllWebsites()
        });

        const getAllWebsites = async () => {
            const response = await axios.get( freeWebsites, {
                params: {}, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            if (response.data.status === 200) {
                allFreeWebsiteData.data = response.data.data.websites;
                allFreeWebsiteData.status = response.data.status
            }
        }

        const copyKey = (e) => {
            let code = e.target.nextSibling.value;
            navigator.clipboard.writeText(code).then(function() {
                toast.add({severity:'success', summary:'Licence Key copied!', detail:'Licence Key copied to clipboard.', life: 2000});
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

        const selectedItem = (e) =>{
            console.log(e);
        }

        /**
         * Website is free status change function
         * @param {UUID} is for unique id of the site
         * @param {isfree} is for the current status of the site
         */
        const switchWebsiteStatus = async (uuid, isfree) => {
            //console.log(uuid, isfree, e);

            //API call start
            try{
                const options = {
                    method: 'put',
                    url: allWebsite,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        isFree: isfree === false ? true : false,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                getAllWebsites();
            } catch(err){
                console.log('err', err);
            }
        }

        //Returing variables to template
        return{
            allFreeWebsiteData,
            switchWebsiteStatus,
            usFormatDate,
            copyKey,
            selectedItem
        }
    }
}
</script>
<style lang="scss" scoped>
input.p-inputtext.p-component.up_copy_license.d-none {
    display: none
}
@import "../../../../assets/style/component/superAdmin/pages/website/WebsiteList";
</style>